import { axiosMedForNgos } from '../utils/axios.util';
import { computed, reactive, toRefs } from 'vue';
import useSettings from './settings.module';
import localforage from 'localforage';
import { waitFor } from '../utils/methods.util';
import useOrganizations from './organizations.module';

const { notify, getTranslation } = useSettings();
const { setHasBasketOrders } = useOrganizations();

const ONE_SECOND_IN_ms = 1000;
const ONE_MINUTE_IN_ms = 60 * ONE_SECOND_IN_ms;
const ORDER_TIMER_STORE_KEY = 'expirationTimestampInms';
const NOTIFICATION_WARNING_SENT = 'notificationWarningSent';
const NOTIFICATION_WARNING_MINUTES_IN_ms = 10 * ONE_MINUTE_IN_ms;

const orderTimerStore = localforage.createInstance({
    name: 'OrderTimerStore',
});

/** @type {import('vue').UnwrapRef<MedForNgos.OrderTimerState>} */
const orderTimerState = reactive({
    msToOrder: 0,
    timeLeftStringRepresentation: computed(() => {
        const minutes = Math.floor(orderTimerState.msToOrder / ONE_MINUTE_IN_ms);
        const seconds = Number(((orderTimerState.msToOrder % ONE_MINUTE_IN_ms) / ONE_SECOND_IN_ms).toFixed(0));

        return seconds === 60 ? (minutes + 1) + ':00' : minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
    }),
    isOrderTimerActive: computed(() => orderTimerState.msToOrder > 0),
    notificationWarningSent: false,
});

export default function useOrderTimers() {
    const initOrderTimerModule = async() => {
        const expirationTimestampInms = await orderTimerStore.getItem(ORDER_TIMER_STORE_KEY) || 0;
        orderTimerState.notificationWarningSent = await orderTimerStore.getItem(NOTIFICATION_WARNING_SENT) || false;

        if (expirationTimestampInms) {
            setModuleAndRunOrderTimer(expirationTimestampInms);
        }
    };

    const checkAndSetOrderDeletionScheduleOnLogin = async() => {
        /** @type {import('axios').AxiosResponse<any>? } */
        const response = await axiosMedForNgos.get('/basket-order-schedule');

        const expirationTimestampInms = response?.data?.expiration_timestamp || 0;

        if (expirationTimestampInms) {
            await orderTimerStore.setItem(ORDER_TIMER_STORE_KEY, expirationTimestampInms);
            setModuleAndRunOrderTimer(expirationTimestampInms);
        }
    };

    /** @param {number} expirationTimestampInms */
    const setModuleAndRunOrderTimer = (expirationTimestampInms) => {
        orderTimerState.msToOrder = expirationTimestampInms - new Date().getTime();

        if (orderTimerState.isOrderTimerActive) {
            runOrderTimer();
        }
    };

    /** @param {number} minutesToOrder */
    const startOrderTimer = async(minutesToOrder) => {
        const expirationTimestampInms = new Date(
            new Date().getTime() + minutesToOrder * ONE_MINUTE_IN_ms,
        ).getTime();

        await orderTimerStore.setItem(ORDER_TIMER_STORE_KEY, expirationTimestampInms);

        orderTimerState.notificationWarningSent = false;

        await orderTimerStore.setItem(NOTIFICATION_WARNING_SENT, orderTimerState.notificationWarningSent);

        orderTimerState.msToOrder = minutesToOrder * ONE_MINUTE_IN_ms;

        runOrderTimer();
    };

    const runOrderTimer = async() => {
        while (orderTimerState.isOrderTimerActive) {
            orderTimerState.msToOrder -= ONE_SECOND_IN_ms;

            if (orderTimerState.msToOrder <= NOTIFICATION_WARNING_MINUTES_IN_ms && !orderTimerState.notificationWarningSent) {
                sendWarningNotification();

                orderTimerState.notificationWarningSent = true;

                await orderTimerStore.setItem(NOTIFICATION_WARNING_SENT, orderTimerState.notificationWarningSent);
            }

            if (orderTimerState.msToOrder <= ONE_SECOND_IN_ms && orderTimerState.isOrderTimerActive) {
                sendDeleteNotification();

                endOrderTimer();
            }

            await waitFor(ONE_SECOND_IN_ms);
        }
    };

    const sendWarningNotification = () => {
        notify({
            text: getTranslation('notifications.warningOrderTimer'),
            status: 'WARNING',
            ms: -1,
        });
    };

    const sendDeleteNotification = () => {
        notify({
            text: getTranslation('notifications.deleteBasketOrder'),
            status: 'ERROR',
            ms: -1,
        });
    };

    const endOrderTimer = async() => {
        orderTimerState.msToOrder = 0;
        orderTimerState.notificationWarningSent = false;
        // will use setHasBasketOrders here because of timer expiration possibility
        setHasBasketOrders(false);

        await orderTimerStore.removeItem(ORDER_TIMER_STORE_KEY);
        await orderTimerStore.removeItem(NOTIFICATION_WARNING_SENT);
    };

    const refsOrderTimerStateObject = toRefs(orderTimerState);

    return {
        ...refsOrderTimerStateObject,
        initOrderTimerModule,
        checkAndSetOrderDeletionScheduleOnLogin,
        startOrderTimer,
        endOrderTimer,
    };
}
