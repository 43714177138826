export default {
    login: {
        email: 'Email',
        password: 'Κωδικός πρόσβασης',
        submit: 'Σύνδεση',
    },
    appName: {
        ngo: 'MEDforNGOs',
        hospital: 'MEDforHOSPs',
    },
    product: {
        med: 'φάρμακο',
        hygProd: 'προϊόν υγείας',
    },
    profile: {
        pageTitle: 'Το προφίλ μου',
        profileSections: {
            logoOrg: {
                title: 'ΛΟΓΟΤΥΠΟ ΦΟΡΕΑ',
            },
            infoData: {
                dataTitle: 'Στοιχεία επικοινωνίας',
                descriptionTitle: 'Περιγραφή λειτουργίας φορέα',
                processTitle: 'Επεξεργασία Στοιχείων Επικοινωνίας',
                name: 'Όνομα φορέα',
                userEmail: 'Email',
                numberOfBeneficiaries: 'Αριθμός ωφελουμένων (μηνιαία)',
                supervisor: 'Όνομα υπεύθυνου επικοινωνίας',
                phone: 'Τηλέφωνο',
                municipality: 'Δήμος',
                regionalUnity: 'Περιφερειακή ενότητα',
                website: 'Ιστοσελίδα',
                address: 'Διεύθυνση',
                description: 'Περιγραφή',
                succesfulSubmittion: 'Τα στοιχεία σας ανανεώθηκαν με επιτυχία.',
            },
            connectToApp: {
                title: 'Σύνδεση στην εφαρμογή',
                processTitle: 'Επεξεργασία Κωδικών εισαγωγής στην πλατφόρμα',
                password: 'Κωδικός πρόσβασης',
                oldPassword: 'Τρέχων κωδικός πρόσβασης',
                newPassword: 'Νέος κωδικός πρόσβασης',
                repeatNewPassword: 'Επιβεβαίωση κωδικού πρόσβασης',
                succesfulSubmittion: 'Ο κωδικός ανανεώθηκε με επιτυχία.',
            },
            openingΗours: {
                title: 'Ωράριο λειτουργίας',
                insertTitle: 'Παρακαλώ εισάγετε το ωράριο λειτουργίας του οργανισμούς σας',
                processTitle: 'Επεξεργασία Ωραρίου Λειτουργίας',
                monday: 'Δευτέρα',
                tuesday: 'Τριτη',
                wednesday: 'Τετάρτη',
                thursday: 'Πέμπτη',
                friday: 'Παρασκευή',
                saturday: 'Σάββατο',
                sunday: 'Κυριακή',
                succesfulSubmittion: 'Οι ώρες εργασίας καταχωρήθηκαν με επιτυχία.',
            },
            officialData: {
                title: 'Επίσημα στοιχεία φορέα',
                insertTitle: 'Παρακαλώ εισάγετε τα επίσημα στοιχεία του οργανισμού σας.',
                official_name: 'Επίσημη ονομασία φορέα',
                headquarters: 'Έδρα',
                legal_representative: 'Νόμιμος εκπρόσωπος',
                afm: 'ΑΦΜ',
                doy: 'ΔΟΥ',
                cooperation_agreement: 'Συμφωνητικό συνεργασίας',
                succesfulSubmittion: 'Επιτυχής δημιουργία επίσημων στοιχείων',
            },
        },
    },
    storage: {
        pageTitle: 'Αποθήκη μου',
        headers: {
            name: 'ΟΝΟΜΑ',
            substance: 'ΔΡΑΣΤΙΚΗ ΟΥΣΙΑ',
            category: 'ΚΑΤΗΓΟΡΙΑ',
            quantity: 'ΠΟΣΟΤΗΤΑ',
            toDonate: 'ΠΡΟΣ ΔΩΡΕΑ',
            state: 'ΚΑΤΑΣΤΑΣΗ ΠΡΟΪΟΝΤΟΣ',
            dateUpdated: 'ΗΜΕΡΟΜΗΝΙΑ ΕΝΗΜΕΡΩΣΗΣ',
            expirationDate: 'ΗΜΕΡΟΜΗΝΙΑ ΛΗΞΗΣ',
            deliveryDate: 'ΗΜΕΡΟΜΗΝΙΑ ΠΑΡΑΔΟΣΗΣ',
            delete: 'ΔΙΑΓΡΑΦΗ',
            company: 'ΕΤΑΙΡΙΑ',
            companyDonator: 'ΕΤΑΙΡΙΑ-ΔΩΡΗΤΗΣ',
            donator: 'ΦΟΡΕΑΣ-ΔΩΡΗΤΗΣ',
            availableQuantity: 'ΔΙΑΘΕΣΙΜΟ ΑΠΟΘΕΜΑ',
            medName: 'ΟΝΟΜΑ ΦΑΡΜΑΚΟΥ',
            hygProdName: 'ΟΝΟΜΑ ΠΡΟΙΟΝΤΟΣ ΥΓΕΙΑΣ',
            modeOfTransport: 'ΤΡΟΠΟΣ ΜΕΤΑΦΟΡΑΣ',
            orderStatus: 'ΚΑΤΑΣΤΑΣΗ ΠΑΡΑΓΓΕΛΙΑΣ',
            beneficiary: 'ΦΟΡΕΑΣ-ΔΙΚΑΙΟΥΧΟΣ',
        },
        headerProps: {
            sortByText: 'Ταξινόμηση με',
        },
        actions: {
            donate: {
                success: 'Η κατάσταση δωρεάς ενημερώθηκε επιτυχώς.',
                donateOnToOff: 'Το φάρμακο πλέον δεν είναι διαθέσιμο για δωρεά.',
                donateOffToOn: 'Το φάρμακο πλέον είναι διαθέσιμο για δωρεά.',
            },
        },
        conditions: {
            pendingDonations: 'Εκκρεμεί δωρεά (ποσότητα παραγγελίας: {total_quantity_to_donate})',
            condSealed: {
                yes: 'Σφραγισμένο',
                no: 'Μη σφραγισμένο',
            },
            condSterilized: {
                yes: 'Αποστειρωμένο',
                no: 'Μη αποστειρωμένο',
            },
            condUsed: {
                yes: 'Χρησιμοποιημένο',
                no: 'Μη χρησιμοποιημένο',
            },
        },
        meds: {
            pageTitle: 'Φάρμακα',
            total: '{total} Είδη Φαρμάκων',
            noDataAlert: '<p class="ml-4 mb-2">Εδώ θα φαίνονται όλα τα φάρμακα που θα καταχωρήσεις.</p><p class="ml-4 mb-0">Καταχώρησε το πρώτο σου φάρμακο πατώντας το κουμπί "Πρόσθεσε φάρμακο" και σκάναρε ή πληκτρολόγησε το barcode του φαρμάκου ή βρες το από την λίστα με όλα τα φάρμακα.</p>',
            firstAlert: 'Έλεγξε ότι η ποσότητα που έχεις δηλώσει για άμεση δωρεά είναι η πραγματική! Δημιουργείται πρόβλημα αν άλλος φορέας παραγγείλει κάτι που είχες ξεχάσει να διαγράψεις.',
            secondAlert: 'Αν το κουτί του φαρμάκου περιέχει λιγότερο από την μισή ποσότητα, δήλωσε την ποσότητα σε χάπια.',
            stored: 'Έχεις καταχωρήσει',
            editDialogTitle: 'Πόσα {measurementUnit} διαθέτεις;',
        },
        hygProds: {
            pageTitle: 'Προϊόντα υγείας',
            total: '{total} Είδη προϊόντων υγείας',
            noDataAlert: '<p class="ml-4 mb-2">Εδώ θα φαίνονται όλα τα προϊόντα υγείας που θα καταχωρήσεις.</p><p class="ml-4 mb-0">Καταχώρησε το πρώτο σου προϊόν υγείας πατώντας στο κουμπί "Πρόσθεσε προϊόν υγείας" και αναζήτησε το στη σχετική λίστα.</p>',
            editDialogTitle: 'Πόσα {quantityType} διαθέτεις;',
        },
        add: {
            pageTitle: 'Προσθήκη',
            addMeds: {
                pageTitle: 'Προσθήκη φαρμάκων στην αποθήκη μου',
                addByBarcode: '1) Καταχώρησε με barcode φαρμάκου',
                scanMedBarcodeAndAdd: {
                    pageTitle: 'Σκάναρε το barcode',
                    linkTextToPage: 'Σκάναρε το barcode',
                    startScanning: 'Σκάναρε',
                    endScanning: 'Τερματισμός',
                    cannotUseScanning: 'Δεν μπορεί να χρησιμοποιηθεί η κάμερα του browser.',
                },
                addByBarcodeHelpText : 'Καταχώρησε με βάση τον 12ψήφιο κάθετο κωδικό από την ταινία γνησιότητας',
                addByList: '2) Καταχώρησε από τη λίστα με όλα τα φάρμακα',
                success: 'Το φάρμακο {new_storage_item} καταχωρήθηκε με επιτυχία',
            },
            addHygProds: {
                pageTitle: 'Προσθήκη προϊόντων υγείας στην αποθήκη μου',
                notFound: 'Δεν βρίσκεις το προϊόν υγείας που θέλεις να καταχωρείς στην αποθήκη σου;',
                suggest: 'Πρότεινε ένα νέο προϊόν υγείας',
                notFoundHygProd: 'Δεν βρίσκεις το προϊόν που σε ενδιαφέρει;',
                clickHereToAddNewHealthProduct: 'Πάτησε εδώ για να προσθέσεις νέο προϊόν υγείας!',
                new: {
                    name: 'Πλήρες όνομα προϊόντος όπως φαίνεται στο κουτί του',
                    category: 'Κατηγορία και υποκατηγορία στην οποία ανήκει',
                    company: 'Εταιρεία που το διακινεί',
                    description: 'Περιγραφή',
                    descriptionNote: '*Δήλωσε μόνο πληροφορίες για το νέο προϊόν. Μην συμπληρώσεις ημ. λήξης, ποσότητα και άλλα στοιχεία. Αφού ελέγξουμε το αίτημα προσθήκης, θα επικοινωνήσουμε για να καταχωρήσεις το προϊόν που θέλεις να δωρίσεις.',
                    price: 'Τιμή',
                    cancel: 'Άκυρο',
                    send: 'Αποστολή',
                    success: 'Το προϊόν {new_hyg_prod_item} καταχωρήθηκε με επιτυχία. Θα ελεγχθεί από το GIVMED και στη συνέχεια θα προστεθεί στη λίστα.',
                },
                total: '{total} Είδη προϊόντων υγείας',
                success: 'Το προϊόν {new_hyg_prod_storage} καταχωρήθηκε με επιτυχία.',
            },
        },
    },
    needs: {
        pageTitle: 'Ανάγκες μου',
        meds: {
            pageTitle: 'Φάρμακα',
            total: '{total} Είδη φαρμάκων',
            boxes: 'Πόσα κουτιά χρειάζεσαι;',
            totalQuantity: '<span class="font-italic"> Έχεις δηλώσει ανάγκες σε: <span class="font-weight-bold"> {totalQuantity} </span>Κουτιά</span>',
            noDataAlert: '<p class="ml-4 mb-2">Εδώ θα φαίνονται όλες οι ανάγκες σου σε φάρμακα. </p><p class="ml-4 mb-0">Καταχώρησε τη πρώτη σου ανάγκη πατώντας στο κουμπί "Νέα ανάγκη" και πληκτρολόγησε την εμπορική ονομασία ή τη δραστική ουσία του φαρμάκου που αναζητάς.</p>',
        },
        hygProds: {
            pageTitle: 'Προϊόντα υγείας',
            total: '{total} Είδη προϊόντων υγείας',
            declared: 'Έχεις δηλώσει ανάγκες σε',
            noDataAlert: '<p class="ml-4 mb-2">Εδώ θα φαίνονται όλες οι ανάγκες σου σε προϊόντα υγείας.</p><p class="ml-4 mb-0">Καταχώρησε την πρώτη σου ανάγκη πατώντας στο κουμπί "Νέα ανάγκη" και πληκτρολόγησε την εμπορική ονομασία ή την κατηγορία/υποκατηγορία που ανήκει το προϊόν υγείας που αναζητάς.</p>',
            editDialogTitle: 'Πόσα {quantityType} χρειάζεσαι;',
        },
        addMeds: {
            pageTitle: 'Προσθήκη φαρμάκων στις ανάγκες μου',
            addByName: '1) Καταχώρησε με εμπορική ονομασία',
            addBySubstance: '2) Καταχώρησε με δραστική ουσία',
            chosenSubstances: 'Δραστικές ουσίες που επέλεξα: ',
            alert: 'Καταχώρησε τις ανάγκες σου με βάση τη δραστική ουσία για να βρεις περισσότερα διαθέσιμα φάρμακα στην παραγγελία.',
            descriptionNote: '*Καταχώρησε τις δραστικές ουσίες κάθε ανάγκης σου ξεχωριστά!',
            success: 'Το φάρμακο {new_need_item} καταχωρήθηκε με επιτυχία.',
            successSubstances: 'Φάρμακα με δραστική/ές ουσία/ες: {new_need_item} καταχωρήθηκε με επιτυχία.',
        },
        addHygProds: {
            pageTitle: 'Προσθήκη προϊόντων υγείας στις ανάγκες μου',
            addByCategory: '2) Καταχώρησε με βάση την κατηγορία/υποκατηγορία',
            success: 'Το προϊόν {new_need_item} καταχωρήθηκε με επιτυχία.',
        },
    },
    order: {
        pageTitle: 'Παραγγελία',
        prods: {
            menuTitle: 'Διαθεσιμότητα',
            pageTitle: 'Διαθέσιμα φάρμακα και προϊόντα υγείας',
            order: 'Το θέλω!',
            matchedNeed: 'Δηλωμένη ανάγκη σε κουτιά: {matchedNeedQuantity}',
            availableForOrder: 'Διαθέσιμα για παραγγελία',
        },
        basket: {
            pageTitle: 'Καλάθι',
            emptyStateTitleBtn: 'Κάνε παραγγελία',
            completed: 'Ολοκλήρωση Παραγγελίας',
            tooltip: 'Αφαίρεσε το φάρμακο που συντηρείται σε ψυγείο αν θέλεις μεταφορά της ACS μέσω GIVMED.',
            noDataAlert: '<p class="ml-4 mb-2">Εδώ θα φαίνονται όλα τα φάρμακα και προϊόντα υγείας που έχεις κρατήσει στο καλάθι σου.</p><span class="ml-4 mb-2">Ολοκλήρωσε τις παραγγελίες σου ώστε να περάσουν στις "Εισερχόμενες δωρεές" → "Σε εξέλιξη".</span>',
        },
        successOrder: 'Ο φορέας {new_donator} έλαβε την παραγγελία σου! Δες την πορεία της στο "Εισερχόμενες δωρεές".',
        completeOrder: 'Η παραγγελία ολοκληρώθηκε',
        givenToAcs: 'Η παραγγελία ανατέθηκε στην ACS',
        givenToAcsModalText: 'Είσαι σίγουρος ότι παρέδωσες το πακέτο στην ACS;',
        downloadDonationForm: 'Λήψη εντύπου δωρεάς',
        isMedMaintaintedInFridge: 'Δεν επιτρέπεται δωρεάν μεταφορά ACS μέσω GIVMED, λόγω συμπερίληψης φαρμάκου που συντηρείται σε ψυγείο.',
    },
    incoming: {
        pageTitle: 'Εισερχόμενες δωρεές',
        pendingOrders: {
            pageTitle: 'Σε εξέλιξη',
            noDataAlert: '<p class="ml-4 mb-2">Εδώ θα φαίνονται οι παραγγελίες που έχεις πραγματοποιήσει, αλλά δεν έχεις παραλάβει ακόμα.</p><p class="ml-4 mb-0">Αφού ολοκληρώσεις την πρώτη σου παραγγελία κατά την διάρκεια του κύκλου παραγγελιών θα εμφανιστούν εδώ οι δωρεές που θα παραλάβεις από άλλους κοινωφελείς φορείς.</p>',
            completedOrder: 'Παρέλαβα',
            completeOrderModalText: 'Είσαι σίγουρος ότι παρέλαβες τα προϊόντα;',
        },
        historyOrders: {
            pageTitle: 'Ιστορικό',
            noDataAlert: '<p class="ml-4 mb-2"> Εδώ θα φαίνονται οι παραγγελίες που έχεις παραλάβει.</p><p class="ml-4 mb-0">Για να περάσουν από το πεδίο "Σε εξέλιξη" στο "Ιστορικό" σου, θα πρέπει να πατήσεις το κουμπί "Παρέλαβα" για κάθε μια παραγγελία που παραλαμβάνεις.</p>',
            orgsTab: 'ΔΩΡΕΕΣ ΑΠΟ ΚΟΙΝΩΦΕΛΕΙΣ ΦΟΡΕΙΣ',
            pharmasTab: 'ΔΩΡΕΕΣ ΑΠΟ ΦΑΡΜΑΚΕΥΤΙΚΕΣ ΕΤΑΙΡΕΙΕΣ',
            showProds: 'Εμφάνιση Προϊόντων',
            hideProds: 'Απόκρυψη Προϊόντων',
        },
    },
    outgoing: {
        pageTitle: 'Εξερχόμενες δωρεές',
        pendingOrders: {
            pageTitle: 'Σε εξέλιξη',
            noDataAlert: '<p class="ml-4 mb-2">Εδώ θα φαίνονται οι παραγγελίες που έχεις δεχτεί κατά τη διάρκεια του τρέχοντος κύκλου παραγγελιών, αλλά δεν έχουν παραδοθεί ακόμα στους δικαιούχους φορείς.</p><p class="ml-4 mb-0">Όταν τις παραλάβουν και πατήσουν το αντίστοιχο κουμπί στο λογισμικό, θα περάσουν στο "Ιστορικό" σου.</p>',
        },
        closeOrgPendingOrders: {
            pageTitle: 'Προς Δωρεά',
            immediatelyDonate: 'Όλα τα φάρμακα και προϊόντα υγείας από την αποθήκη σου που έχουν δηλωθεί προς ΑΜΕΣΗ δωρεά, θα δοθούν στον φορέα-παραλήπτη της επιλογής σου.',
            notProdsForImmediatelyDonate: '<p class="ml-4 mb-2">Είτε δεν υπάρχουν φάρμακα και προϊόντα υγείας για ΑΜΕΣΗ δωρεά στην αποθήκη σου, είτε έχουν δηλωθεί προς ΑΜΕΣΗ δωρεά σε κάποιον συνεργάτη και όταν παραληφθούν θα εμφανιστούν στο ιστορικό σου.</p><p class="ml-4 mb-0">Για περισσότερες πληροφορίες επικοινώνησε με την ομάδα του GIVMED.</p>',
            recievedOrg: 'Φορέας που θα παραλάβει',
            readyForDelivery: 'Έτοιμα για παράδοση',
            checkOrg: 'Επιβεβαίωσε ότι ο οργανισμός: {org_name} μπορεί να δεχτεί τα προϊόντα που θέλεις να δωρίσεις.',
            selectedOrg: 'Αναμένεται η επιβεβαίωση παραγγελίας από: {org_name}',
            phone: 'Τηλέφωνο επικοινωνίας: {org_phone}',
            supervisor: 'Υπεύθυνος: {org_supervisor}',
            supervisorMail: 'Email επικοινωνίας: {org_supervisor_mail}',
        },
        historyOrders: {
            pageTitle: 'Ιστορικό',
            noDataAlert: '<p class="ml-4 mb-2">Εδώ θα φαίνονται οι παραγγελίες που έχεις δεχτεί και έχουν παραδοθεί επιτυχώς στους δικαιούχους φορείς.</p><p class="ml-4 mb-0">Για να περάσουν από το πεδίο "Σε εξέλιξη" στο "Ιστορικό" σου, θα πρέπει  να πατήσουν το κουμπί "Παρέλαβα".</p>',
        },
    },
    faq: {
        title: 'Συχνές ερωτήσεις',
    },
    logout: 'Αποσύνδεση',
    table: {
        loading: 'Φόρτωση...',
        noData: 'Δεν προέκυψαν αποτελέσματα',
    },
    quantityType: {
        BOX: 'Κουτί | Κουτιά',
        BAG: 'Σακούλα | Σακούλες',
        PIECE: 'Τεμάχιο (υπομονάδα κουτιού) | Τεμάχια (υπομονάδες κουτιού)',
        OTHER: 'Άλλο | Άλλα',
        ML: 'ml',
        pieces: 'Τεμάχιο | Τεμάχια',
        subUnit: '(υπομονάδα κουτιού) | (υπομονάδες κουτιού)',
    },
    extraQuantityType: {
        BOX: 'κουτιά',
        BAG: 'σακούλες',
        PIECE: 'τεμάχια (υπομονάδες κουτιού)',
        OTHER: 'άλλα',
    },
    measurementUnit: {
        B: 'Κουτί | Κουτιά',
        P: 'Χάπι | Χάπια',
    },
    extraΜeasurementUnit: {
        B: 'κουτιά',
        P: 'χάπια',
    },
    conFormType: {
        CAPSULES: 'Κάψουλες',
        AMPULES: 'Αμπούλες',
        TABLETS: 'Ταμπλέτες',
        SYRUP: 'Σιρόπια',
        CREAM: 'Κρέμες',
        COL: 'Κολλύρια',
        INHALER: 'Eισπνεόμενα',
        GEL: 'Ζελέ',
        INJECTION: 'Ένεσεις',
        SACH: 'Φακελάκια',
        NASAL_SPRAY: 'Ρινικά σπρεϊ',
        OTHER: 'Άλλα',
    },
    conUnitType: {
        ml: 'ml',
        mg: 'mg',
        g: 'g',
        mcg: 'mcg',
        percent: '%',
        Other: 'Άλλο',
    },
    filters: {
        searchBy: {
            nameOrSubstance: 'Αναζήτησε το όνομα ή την δραστική ουσία του φαρμάκου',
            nameOrSubCategory: 'Αναζήτησε το όνομα ή την υποκατηγορία του προϊόντος υγείας',
            healthProduct: 'Αναζήτησε προϊόν υγείας (πληκτρολόγησε 3 γράμματα)',
        },
        kindOfProduct: {
            hygProds: 'Προϊόντα υγείας',
            meds: 'Φάρμακα',
        },
        categories: {
            sanitaryΜaterial: 'Yγειονομικό υλικό',
            parapharmaceuticals: 'Παραφάρμακα',
            orthopedics: 'Ορθοπεδικά είδη',
        },
        company: 'Εταιρία',
    },
    actions: {
        edit: {
            image: 'Προσθήκη/Αλλαγή εικόνας',
            profileData: 'Επεξεργασία',
            tooltip: 'Κάνε κλικ για επεξεργασία',
            cancel: 'ΑΚΥΡΟ',
            save: 'Αποθήκευση',
            saveDialog: 'ΑΠΟΘΗΚΕΥΣΗ',
            updateDialog: 'ΕΝΗΜΕΡΩΣΗ',
            continue: 'Συνέχεια',
            success: 'Επιτυχής ενημέρωση',
            successEntry: 'Επιτυχής καταχώρηση',
            manyResults: 'Διαθέσιμο σε διαφορετικούς φορείς ή ημερ. λήξης',
            order: 'ΠΑΡΑΓΓΕΛΙΑ',
            clickToOrder: 'Κάνε κλικ για παραγγελία',
            getMore: 'Δες περισσότερα',
        },
        deleteModal: {
            text: {
                product: 'Είσαι σίγουρος για την διαγραφή του συγκεκριμένου προϊόντος;',
                order: 'Είσαι σίγουρος για την διαγραφή της συγκεκριμένης παραγγελίας και <span class="font-weight-bold text-decoration-underline">όλων των προϊόντων</span> της;',
            },
            cancel: 'Έξοδος',
            no: 'ΟΧΙ',
            yes: 'ΝΑΙ',
            success: 'Η διαγραφή ολοκληρώθηκε επιτυχώς.',
        },
    },
    transportMethods: {
        PICK_UP: 'Θα παραλάβω ο/η ίδιος/α',
        OWN_COURIER: 'Μεταφορά με χρέωση courier του παραλήπτη/τριας',
        GIVMED_ACS_COURIER: 'Δωρεάν μεταφορά της ACS μέσω GIVMED',
    },
    donationStatus: {
        BASKET: 'Καλάθι',
        PREPARATION: 'Σε προετοιμασία',
        ON_THE_WAY: 'Παραδόθηκε σε ACS Courier',
        COMPLETED: 'Ολοκληρώθηκε',
    },
    labels: {
        med: 'Όνομα φαρμάκου',
        hygProd: 'Όνομα προϊόντος υγείας',
        medsWithSameSubstance: 'Στην Ελλάδα κυκλοφορούν {meds_count_from_substances} φάρμακα με αυτή τη δραστική ουσία',
        quantity: 'Ποσότητα',
        dateCreated: 'Ημερομηνία δημιουργίας',
        updateDate: 'Ημερομηνία Ενημέρωσης',
        isMaintainedInFridge: 'Το φάρμακο συντηρείται σε ψυγείο.',
        expirationDate: 'Ημερομηνία λήξης',
        quantityType: 'Μονάδα προϊόντος',
        conForm: 'Μορφή',
        conUnit: 'Μονάδα προϊόντος',
        concentration: 'Συγκέντρωση',
        donationState: 'Το καταχωρείς προς δωρεά ή αποθήκευση;',
        condition: 'Κατάσταση',
        toDonate: 'Διαθέσιμο προς δωρεά άμεσα',
        notToDonateNow: 'Αποθήκευση στο φαρμακείο μου',
        used: 'Χρησιμοποιημένο',
        sterilized: 'Αποστειρωμένο',
        sealed: 'Σφραγισμένο',
        delete: 'Διαγραφή',
        deleteOrder: 'Διαγραφή παραγγελίας',
        cancel: 'Ακύρωση',
        addToStorage: 'Προσθήκη στην αποθήκη μου',
        addToNeeds: 'Προσθήκη στις ανάγκες μου',
        yes: 'Ναι',
        no: 'Όχι',
        unknown: 'Άγνωστο',
        itemsPerPage: 'Γραμμές ανα σελίδα',
        from: 'από',
        workingHoursFrom: 'Από',
        workingHoursTo: 'Μέχρι',
        public: 'Δημόσια',
        website: 'https://www.example.com/page',
        beneficiaryDocuments: 'Δικαιολογητικά που χρειάζονται για την παροχή φαρμάκων και προϊόντων υγείας',
        agreeWithTerms:'Συμφωνώ με τους όρους συνεργασίας',
        agree: 'Συμφωνώ',
        signature: 'Υπογραφή',
        terms: 'Όροι συνεργασίας',
        time: 'Ώρες(ΧΧ):(Λεπτά)(ΧΧ)',
        newMed: 'Πρόσθεσε φάρμακο',
        newHygProd: 'Πρόσθεσε προϊόν υγείας',
        newNeed: 'Νέα ανάγκη',
        barcode: 'Barcode',
        insertBarcode: 'Πληκτρολόγησε τον 12ψήφιο κάθετο κωδικό από την ταινία γνησιότητας',
        selectTransportMethod: 'Επίλεξε τρόπο μεταφοράς',
        medNotFound: 'Δεν βρίσκεις το φάρμακό σου στη λίστα;',
        medNotFoundNotification: 'Στείλε μας στο hello@givmed.org το όνομα του φαρμάκου που δεν βρήκες.',
        medBarcodeNotFound: 'Δεν αναγνωρίστηκε το barcode σου;',
        medBarcodeNotFoundNotification: 'Στείλε μας στο hello@givmed.org το αριθμό του barcode που δεν αντιστοιχήθηκε με φάρμακο.',
        authenticityΒand: 'Δεν έχεις το κουτί φαρμάκου ή λείπει η ταινία γνησιότητας;',
        autoChoiceBasedOnNeeds: 'Αυτόματη επιλογή με βάση τις καταγεγραμμένες ανάγκες σου',
        municipalitiesToOrder: 'Επίλεξε δήμους που θέλεις να ψάξεις',
        organizationsToOrder: 'Επίλεξε φορέα από τον οποίο θες να παραγγείλεις',
        quantityOrder: 'Ποσότητα παραγγελίας',
        chooseSubstances: 'Επίλεξε μία η περισσότερες δραστικές ουσίες',
        productType: 'Είδος προϊόντων που εμφανίζεται στον παρακάτω πίνακα:',
        freeTransports: 'Διαθέσιμες δωρεάν μεταφορές με ACS: {free_transports}',
        successAddToBasket: 'Το προϊόν προστέθηκε στο Καλάθι.',
        orders: 'Παραγγελίες: {total_orders}',
        donators: 'Επίλεξε φορέα-δωρητή',
        beneficiaries: 'Επίλεξε φορέα-δικαιούχο',
        donationCycles: 'Περίοδος δωρεάς',
        modesOfTransport: 'Επίλεξε τρόπο μεταφοράς',
        pharmas: 'Επίλεξε φαρμακευτική',
        basket: 'Καλάθι μου({free_transports})',
        pendingDeliveries: 'Προς παράδοση: {total_quantity_to_donate}',
        timer: 'Λεπτά για ολοκλήρωση παραγγελίας',
        approximatePrice: 'Αξία προϊόντων κατά προσέγγιση: {totalPrice}€',
        approximatePriceAtLeastOneWithoutPrice: 'Αξία προϊόντων κατά προσέγγιση: {totalPrice}€ <p style="font-size:12px;">*Η τιμή κάποιων προϊόντων δεν συνυπολογίζεται, λόγω έλλειψης δεδομένων.</p>',
        approximateTotalPrice: '{totalHistoryPrice}€ η συνολική αξία προϊόντων κατά προσέγγιση από κοινωφελείς φορείς και φαρμακευτικές εταιρείες.',
        approximateTotalPriceAtLeastOneWithoutPrice: '{totalHistoryPrice}€ η συνολική αξία προϊόντων κατά προσέγγιση από κοινωφελείς φορείς και φαρμακευτικές εταιρείες. <p class="mb-0 text-caption"">*Η τιμή κάποιων προϊόντων δεν συνυπολογίζεται, λόγω έλλειψης δεδομένων.</p>',
        approximateTotalPriceByOrgs: '{totalHistoryPriceDonatedByOrgs}€ η συνολική αξία προϊόντων κατά προσέγγιση από κοινωφελείς φορείς.',
        approximateTotalPriceByOrgsAtLeastOneWithoutPrice: '{totalHistoryPriceDonatedByOrgs}€ η συνολική αξία προϊόντων κατά προσέγγιση από κοινωφελείς φορείς. <p class="mb-0 text-caption"">*Η τιμή κάποιων προϊόντων δεν συνυπολογίζεται, λόγω έλλειψης δεδομένων.</p>',
        approximateTotalPriceByOrgsHistoryOutgoing: '{totalHistoryPriceDonatedByOrgs}€ η συνολική αξία προϊόντων κατά προσέγγιση προς κοινωφελείς φορείς και δημόσιες δομές υγείας.',
        approximateTotalPriceByOrgsAtLeastOneWithoutPriceHistoryOutgoing: '{totalHistoryPriceDonatedByOrgs}€ η συνολική αξία προϊόντων κατά προσέγγιση προς κοινωφελείς φορείς και δημόσιες δομές υγείας. <p class="mb-0 text-caption"">*Η τιμή κάποιων προϊόντων δεν συνυπολογίζεται, λόγω έλλειψης δεδομένων.</p>',
        approximateTotalPriceByPharmas: '{totalHistoryPriceDonatedByPharmas}€ η συνολική αξία προϊόντων κατά προσέγγιση από φαρμακευτικές εταιρείες.',
        tooltipBasketTitle: 'Το καλάθι μου',
        scrollRight: 'Κατεύθυνε (scroll) την οθόνη προς τα δεξιά για να δεις όλο τον πίνακα.',
        and: 'και',
        forReceipts: 'Για παραλαβές από εκπρόσωπο του φορέα σου ή με δικιά σου χρέωση courier, θα πρέπει να έχουν περάσει 3 ημέρες ή να έχεις επιβεβαιώσει με τον φορέα-δωρητή εκ των προτέρων.',
        price: 'Τιμή προϊόντος: {price}€',
        beneficiary: 'δικαιούχος',
        donator: 'δωρητής',
        orgNoMoreExists: 'Ο συγκεκριμένος φορέας-{typeOfOrg} δεν αναγνωρίζεται πια',
        fileFormat: 'Μορφή αρχείου',
        addHygProdExpDate: 'Αν το προϊόν υγείας έχει ημερομηνία λήξης, καταχώρησέ την εδώ.',
        back: 'Πίσω',
    },
    notifications: {
        successUpdateProduct: 'Επιτυχής ανανέωση προϊόντος',
        successDeleteProduct: 'Επιτυχής διαγραφής προϊόντος',
        successDeleteOrder: 'Επιτυχής διαγραφής παραγγελίας',
        scanningIsNotLoading: 'Η βιβλιοθήκη αναγνώρισης χαρακτήρων δεν κατάφερε να φορτώσει. Παρακαλώ κάνε επαναφόρτωση της σελίδας.',
        warningOrderTimer: 'Ευγενική υπενθύμιση, μην ξεχάσεις να ολοκληρώσεις την παραγγελία σου.',
        deleteBasketOrder: 'Τα φάρμακα που είχες στο καλάθι σου διαγράφηκαν. Είναι διαθέσιμα για παραγγελία προς όλους τους χρήστες. Μπορείς να τα προσθέσεις ξανά στο καλάθι σου, αλλά αυτή την φορά ολοκλήρωσε την παραγγελία εντός του χρονικού ορίου. Ανανέωσε την σελίδα και ξεκίνα ξανά!',
    },
    placeholders: {
        expDataMed: 'μμ-εεεε',
        expDataHygProd: 'ηη-μμ-εεεε',
    },
    download: {
        text: 'Λήψη',
        tooltip: 'Η λήψη θα πραγματοποιηθεί βάσει των επιλεγμένων φίλτρων.',
    },
    rules: {
        emailValidation: 'Παρακαλώ γράψε έγκυρο email.',
        quantity: 'Μη επιτρεπτή ποσότητα',
        minLength: 'Πληκτρολόγησε τουλάχιστον 3 χαρακτήρες.',
        minLengthPassword: 'Πληκτρολόγησε τουλάχιστον 8 χαρακτήρες.',
        minLengthMedExpiredDate: 'Πληκτρολόγησε και τα 6 ψηφία της ημερομηνίας λήξης(μμ-εεεε).',
        minLengthHygProdExpiredDate: 'Πληκτρολόγησε και τα 8 ψηφία της ημερομηνίας λήξης(ηη-μμ-εεεε).',
        maxLengthDescription: 'Πληκτρολόγησε μέχρι 1000 χαρακτήρες.',
        notEmptyField: 'Το πεδίο αυτό δεν μπορεί να είναι κενό.',
        passwordsNotMatch: 'Οι κωδικοί πρόσβασης δεν ταιριάζουν.',
        lengthPhone: 'Πληκτρολόγησε ακριβώς 10 αριθμούς.',
        lengthAfm: 'Πληκτρολόγησε ακριβώς 9 αριθμούς.',
        lengthBarcode: 'Πληκτρολόγησε ακριβώς 12 αριθμούς.',
        orderQuantity: 'H ποσότητα παραγγελίας υπερβαίνει την ποσότητα αποθέματος. Ποσότητα αποθέματος {availableQuantity}.',
        updateOrderQuantity: 'H ποσότητα παραγγελίας υπερβαίνει την ποσότητα αποθέματος. Πρέπει να μειώσετε την ποσότητα παραγγελίας κατά {orderedQuantityDiff}.',
        quantityOverZero: 'Η ποσότητα παραγγελίας πρέπει να είναι μεγαλύτερη από 0.',
        concentrationQuantityOverZero: 'Η ποσότητα συγκέντρωσης πρέπει να είναι μεγαλύτερη από 0.',
        isExpired: 'Η ημερομηνία είναι εκπρόθεσμη.',
        isValid: 'Η ημερομηνία δεν είναι έγκυρη.',
    },
    // settings: {
    //     title: 'Ρυθμίσεις',
    //     langs: {
    //         select: 'Επιλογή γλώσσας',
    //     },
    // },
    newAppVersionAvailable: {
        text: 'Υπάρχει νέα έκδοση διαθέσιμη. Κατέβασέ την!',
        btn: 'Αναβάθμιση',
    },
    errorCodes: {
        serverConnectionProblem: 'Προέκυψε τεχνικό πρόβλημα. Επικοινώνησε μαζί μας.',
        timeoutExceeded: 'Timeout exceeded. The request did not get a response in time.',
        generalValidationError: 'Λανθασμένα στοιχεία εισόδου.',
        availableQuantityNegative: 'Δεν μπορείτε να μειώσετε την ποσότητα σε αυτό το νούμερο γιατί εκκρεμεί δωρεά.',
        deletionNotPermittedPendingDonation: 'Η διαγραφή δεν επιτρέπεται διότι εκκρεμεί δωρεά.',
        multipleObjectsReturned: 'Πολλαπλά στοιχεία επιστρέφησαν',
        accountDisabled: 'Ο λογαριασμός του χρήστη έχει απενεργοποιηθεί.',
        userAlreadyExists:  'Αυτό το email χρησιμοποιείται ήδη από κάποιον άλλον χρήστη.',
        wrongCredentials: 'Το email ή ο κωδικός είναι λάθος.',
        validationErrorException: 'An exception occured',
        wrongCurrentPassword: 'Λανθασμένος ισχύων κωδικός',
        authenticationFailed: 'Έχετε αποσυνδεθεί. Παρακαλώ συνδεθείτε ξανά.',
        coveredQuantity: 'Covered quantity greater than quantity in need.',
        sendEmailException: 'An error occured while trying to send email to user',
        transactionIntegrityError: 'Transaction integrity error',
        isMedNarcotic: 'Στο δίκτυο του GIVMED δεν πραγματοποιούνται δωρεές φαρμάκων που χορηγούνται με ειδική συνταγογράφηση ναρκωτικών.',
        isMedExpired: 'Δεν επιτρέπεται η καταχώρηση ληγμένων φαρμάκων.',
        isHygProdExpired: 'Δεν επιτρέπεται η καταχώρηση ληγμένων προϊόντων υγείας.',
        eofMedInfoNotFoundFromGivenBarcode: 'Το barcode που πληκτρολόγησες δεν αναγνωρίστηκε από τον εθνικό οργανισμό φαρμάκων. Προσπάθησε ξανά! αν χρειάζεσαι βοήθεια επικοινώνησε μαζί μας στο hello@givmed.org',
        quantityZeroOrNegative: 'Αν δεν χρειάζεσαι πλέον αυτό το {product}, διάγραψέ το!',
        failureUpdateProduct: 'Αποτυχία ανανέωση προϊόντος',
        failureDeleteProduct: 'Αποτυχία διαγραφής προϊόντος',
        orderedQuantityGreaterThanAvailableQuantity: 'Η ποσότητα παραγγελίας υπερβαίνει τη διαθέσιμη ποσότητα. Η διαθέσιμη ποσότητα είναι {value}.',
        orderedQuantityGreaterThanAvailableQuantityInExistingOrder: 'Η ποσότητα παραγγελίας υπερβαίνει τη διαθέσιμη ποσότητα που έχει ο φορέας. Πρέπει να μειώσετε την ποσότητα κατά {value}.',
        newOrderQuantityGreaterThanOld: 'Δεν επιτρέπεται η αύξηση της ποσότητας παραγγελίας του προϊόντος. Μπορείς να μειώσεις την ποσότητα ή να διαγράψεις τελείως το προϊόν.',
        orderedMedsMaintainedInFridgeCannotBeTransportedViaACS: 'Τεχνικό πρόβλημα λόγω φαρμάκου που συντηρείται σε ψυγείο. Επικοινωνήστε με την ομάδα του GIVMED στο hello@givmed.org, 210 3007 222.',
    },
};
