<template>
    <transition name="custom-transition">
        <v-snackbar
            v-if="notification.show"
            v-model="notification.show"
            multi-line
            style="top: 50px; position: fixed;"
            rounded="xl"
            top
            right
            :timeout="getms"
            :color="notificationColor"
            elevation="24"
            @input="dismissNotification"
        >
            <div class="d-flex align-center">
                <v-icon>{{ iconChoice }}</v-icon>
                <span class="ml-4">{{ notification.text }}</span>
            </div>

            <template #action="{ attrs }">
                <v-btn dark text v-bind="attrs" @click="dismissNotification">
                    <v-icon>{{ ICONS.close }}</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </transition>
</template>

<script>
import { defineComponent } from 'vue';
import useSettings from '../../modules/settings.module';
import { ICONS } from '../../constants';

export default defineComponent({
    setup() {
        const { notification, dismissNotification } = useSettings();

        return {
            notification, dismissNotification,
        };
    },
    data() {
        return {
            ICONS,
        };
    },
    computed: {
        getms() {
            if (this.notification.status === 'ERROR' || this.notification.status === 'WARNING') {
                return this.notification.ms ?? 6000;
            } else {
                return this.notification.ms ?? 3000;
            }
        },
        iconChoice() {
            switch (this.notification.status) {
                case 'ERROR':
                    return ICONS.alert;
                case 'WARNING':
                    return ICONS.info;
                case 'SUCCESS':
                    return ICONS.success;
                default:
                    return ICONS.success;
            }
        },
        notificationColor() {
            switch (this.notification.status) {
                case 'ERROR':
                    return 'red accent-4';
                case 'WARNING':
                    return 'orange accent-4';
                case 'SUCCESS':
                    return 'success';
                default:
                    return 'success';
            }
        },
    },
});
</script>

<style scoped>
.custom-transition-enter-active,
.custom-transition-leave-active {
    transition: opacity 0.4s ease, transform 0.4s ease;
}
.custom-transition-enter,
.custom-transition-leave-to {
    opacity: 0;
    transform: translateX(20px);
}
</style>

